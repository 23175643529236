import { enableScrollLock, disableScrollLock } from '@/utils'
export default {
  components: {
    Close: () => import(/* webpackChunkName: "icon-close" */ '@/components/icons/Close')
  },
  props: {
    modalVisible: {
      type: Boolean,
      default: false
    },
    closeIconVisible: {
      type: Boolean,
      default: true
    },
    id: {
      type: String
    },
    initFunction: {
      type: Function
    },
    width: {
      type: String,
      default: '500px'
    },
    type: {
      type: String,
      default: 'regular'
    },
    corner: {
      type: String,
      default: 'rounded'
    }
  },
  watch: {
    $route: function () {
      disableScrollLock()
    },
    modalVisible(val) {
      if (val) {
        enableScrollLock()
        if (this.initFunction) this.initFunction()
      } else {
        disableScrollLock()
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    }
  }
}
